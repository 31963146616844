<template>
  <section class="section our-company" id="ourcompany" v-bind="initData">
    <div v-if="info">
      <section class="our-company-top">
        <img src="../../assets/img/Logo_Models.svg" />
      </section>
      <section class="our-company-content" :style="`--coverDesktop: url(${info.ourCompanyCover});`">
        <div class="our-company-content-advice">
          <div class="our-company-content-advice-picture">
            <img :src="info.ourCompanyCoverMobile" loading="lazy" alt="our company" />
          </div>
          <div class="our-company-content-advice-text">
            <div>
              <div class="short" v-html="getShort(removeHTMLEntities(info.text), 251)"></div>
              <div class="long" v-html="removeHTMLEntities(info.text)"></div>
            </div>
            <div class="our-company-content-advice-options">
              <router-link class="third-button button pointer desktop" :to="{ path: '/about' }">
                {{ info.btnText }}
              </router-link>
              <a class="third-button button pointer mbl" @click="toggleClass('.our-company-content-advice-text', 'expand')">
                {{ info.btnText }}
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
  import store from "../../store/index.js";
  import global from "@/assets/js/global.js";
  export default {
    data: function () {
      return {
        info: null,
      };
    },
    methods: {
      getLanguage: async function () {
        if (store.getters["iflanguage"]) {
          let dataLang = await store.getters["dataLang"];
          this.info = dataLang.ourCompany;
        }
      },
      getShort: (text, limit) => {
        return global.getShort(text, limit);
      },
      /**
       * Funcion encargada de reemplazar las entidades html por su representacion
       * @param {*} textoHTML
       */
      removeHTMLEntities: function (textoHTML) {
        const entidadesHTML = {
          "&lt;": "<",
          "&gt;": ">",
          "&amp;": "&",
          "&quot;": '"',
          "&apos;": "'",
          "&nbsp;": " ",
        };

        let textoLimpio = textoHTML;
        for (const entidad in entidadesHTML) {
          if (entidadesHTML.hasOwnProperty(entidad)) {
            const expresionRegular = new RegExp(entidad, "g");
            textoLimpio = textoLimpio.replace(expresionRegular, entidadesHTML[entidad]);
          }
        }
        return textoLimpio;
      },
      toggleClass: (elem, clss) => {
        document.querySelector(elem).classList.toggle(clss);
      },
    },
    computed: {
      initData: async function () {
        this.getLanguage();
      },
    },
  };
</script>

<style lang="scss">
  @import "@/assets/styles/vars.scss";
  .our-company {
    position: relative;
    &-top {
      @include Flex(row);
      padding: 25px 0;
    }
    &-content {
      position: relative;
      padding: 0 $mpadding;
      background-image: none;
      background-position: top center;
      background-size: cover;
      background-attachment: fixed;
      &-advice {
        padding: $mpadding * 2;
        position: relative;
        border-radius: $mradius;
        background: #eeeeee;
        margin: 0 auto;
        box-shadow: $dshadow;
        text-align: center;
        line-height: 1.625;
        &-picture {
          display: block;
          img {
            margin: auto;
            max-width: 100%;
            border-radius: $mradius;
          }
        }
        &-text {
          .long {
            display: none;
          }
          &.expand {
            .short,
            .button.mbl {
              display: none;
            }
            .long {
              display: block;
              > div p {
                margin: 0 0 $mpadding 0;
              }
            }
          }
        }
        &-options {
          margin-top: $mpadding * 2;
          text-align: center;
          .button {
            color: $white;
            background-color: $primary_color;
            transition: 0.3s ease-in-out;
            border-radius: 20px;
            &:hover {
              border: solid 1px;
              color: $primary_color;
              background-color: #fff;
            }
            &.mbl {
              display: initial;
            }
            &.desktop {
              display: none;
            }
          }
        }
      }
    }
    @media screen and (min-width: 1025px) and (max-width: 1439px) {
      .our-company-content {
        background-image: var(--coverDesktop);
        background-attachment: fixed;
        background-position: center -150px;
        background-size: contain;
      }
    }
    @media (min-width: $desktop_width) {
      &-content {
        min-height: 76vh;
        background-image: var(--coverDesktop);
        &-advice {
          position: absolute;
          background: transparent;
          box-shadow: 0 0 0;
          color: $white;
          top: 50%;
          right: 8%;
          transform: translate(5%, -50%);
          -ms-transform: translate(0, -50%);
          text-align: left;
          &-picture {
            display: none;
          }
          &-text {
            width: 70%;
            margin: auto;
            &.expand {
              .short {
                display: block;
              }
              .long {
                display: none;
              }
            }
          }
          &-options {
            text-align: left;
            .button {
              color: $primary_color;
              background-color: #fff;
              transition: 0.3s ease-in-out;
              &.mbl {
                display: none;
              }
              &.desktop {
                display: initial;
              }
              &:hover {
                border: none;
                color: #fff;
                background-color: $primary_color;
              }
            }
          }
          &::after {
            content: "";
            position: absolute;
            top: 0;
            z-index: -1;
            background-color: $primary_color_opacity;
            height: 100%;
            width: 78%;
            right: 12%;
            transform: skew(20deg);
          }
          &::before {
            content: "";
            position: absolute;
            top: 0;
            z-index: -1;
            background-color: $primary_color_opacity;
            height: 100%;
            width: 3%;
            right: 7%;
            transform: skew(20deg);
          }
        }
      }
    }

    @include Desktop {
      .our-company-content-advice {
        transform: translate(8%, -50%);
        max-width: 60%;
        &-text {
          width: 70%;
          margin: auto;
        }
        &::after {
          width: 72%;
          right: 15%;
        }
        &::before {
          right: 10%;
        }
      }
    }
    @media (min-width: $tv_width) {
      .our-company-content-advice {
        max-width: 50%;
        transform: translate(15%, -50%);
        &::after {
          width: 76%;
          right: 12%;
        }
        &::before {
          right: 7%;
        }
      }
    }
  }
</style>
